@import '../utils/mediaQueries';

.react-datepicker {
  &__day {
    color: $primary;
    font-weight: $font-weight-bold;
    border-radius: 0 !important;
    margin: 0 !important;

    &-names .react-datepicker__day-name {
      margin: 0;
    }

    &--today {
      background-color: $white;
      color: initial;

      &:hover {
        background-color: $lightgray;
      }

      &.react-datepicker__day--in-range {
        outline: 0;
      }
    }

    &--disabled,
    &--excluded {
      color: $gray-400;
      font-weight: 400;
    }

    &--selected {
      background-color: $green;
      color: $white;

      &:hover {
        background-color: $green;
      }

      &.react-datepicker__day--in-selecting-range {
        background-color: $green !important;
      }
    }

    &--in-selecting-range {
      background-color: lighten($green, 25%) !important;
      color: $white;

      &.react-datepicker__day--within-invalid-range {
        background-color: $orangered !important;
      }
    }

    &--in-range {
      background-color: $green !important;
      color: $white;

      &.react-datepicker__day--within-invalid-range {
        background-color: $orangered !important;
      }
    }

    &--keyboard-selected {
      background-color: transparent;
      color: $primary;

      &:hover {
        background-color: $lightgray;
      }

      &.react-datepicker__day--in-selecting-range,
      &.react-datepicker__day--in-range {
        color: $white;
      }
    }

    &__popup {
      background-color: $tertiary;
      color: $white;
      border: 1px solid darken($tertiary, 20%);

      > * {
        line-height: 1.1rem;
      }
    }

    &--within-invalid-range {
      background-color: $orangered;

      &.react-datepicker__day--excluded,
      &.react-datepicker__day--disabled {
        color: $white;
        border-radius: 0.3rem;
      }
    }
  }

  &__navigation-icon::before {
    border-color: $white;
  }
}
.react-datepicker-popper[data-placement^='bottom'] {
  @include medium {
    transform: translate3d(-71px, 60px, 0px) !important;
  }
  @include large {
    transform: translate3d(46px, 60px, 0px) !important;
  }
}

.react-datepicker-popper .react-datepicker .react-datepicker__triangle {
  @include medium {
    transform: translate3d(267.4px, 0px, 0px) !important;
  }
  @include large {
    transform: translate3d(204.4px, 0px, 0px) !important;
  }
  @include xlarge {
    transform: translate3d(157.4px, 0px, 0px) !important;
  }
}

@import '../Vars';

.popover {
  font-family: var(--font-family-base);
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $base-1;
}

.bodyClass {
  background-color: $background;

  .container-fluid {
    background-color: $background;
    max-width: map-get($container-max-widths, 'xxl');
    padding-left: 16px;
    padding-right: 16px;

    @include small {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

h1 {
  font-size: 32px;
  font-family: var(--font-family-base);
  line-height: 44px;
  font-weight: $font-weight-bold;
  color: $base-1;
}

h2 {
  font-size: 28px;
  font-family: var(--font-family-base);
  line-height: 38px;
  font-weight: $font-weight-bold;
  color: $base-1;
}

h3 {
  font-size: 20px;
  font-family: var(--font-family-base);
  line-height: 28px;
  font-weight: $font-weight-bold;
  color: $base-1;
}

h4 {
  font-size: 14px;
  font-family: var(--font-family-base);
  line-height: 18px;
  font-weight: $font-weight-bold;
  color: $base-1;
}

.text-1 {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--font-family-base);
}

.text-2 {
  font-size: 15px;
  line-height: 17px;
  font-family: var(--font-family-base);
}

.text-3 {
  font-size: 18px;
  line-height: 20px;
  font-family: var(--font-family-base);
}

.text-4 {
  font-size: 20px;
  line-height: 22px;
  font-family: var(--font-family-base);
}

.alert {
  margin-top: 10px;
}

.alert-warning {
  color: $alert-warning-color;
  background-color: $alert-warning-background-color;
  border-color: $alert-warning-border-color;
}

.alert-danger {
  color: $alert-danger-color;
  background-color: $alert-danger-background-color;
  border-color: $alert-danger-border-color;
}

.alert-success {
  color: $alert-success-color;
  background-color: $alert-success-background-color;
  border-color: $alert-success-border-color;
}

.alert-info {
  background-color: $info;
  border-color: $info;
  color: $white;
}

.travel-advice__content {
  svg {
    color: $tertiary;
  }

  + .btn.btn-secondary {
    @extend .btn-secondary--stroked;
  }
}

.camper-specials__label {
  background-color: $tertiary !important;
}

.custom-pagination-input {
  height: 38px !important;
}

nav[aria-label='pagination'] {
  display: flex;
  justify-content: center;

  > ul {
    box-shadow: 0 0 8px $gray-400;
    border-radius: 6px;
    overflow: hidden;
  }
}

.pdf-viewer {
  button {
    height: 38px !important;
  }
}

.service-box {
  display: flex;

  &__outer {
    display: none;

    @include large {
      display: block;
    }

    > div {
      margin-bottom: 0;
    }
  }

  &__icons {
    position: relative;

    svg {
      color: $tertiary;

      &:first-child {
        font-size: 25px;
      }

      &:last-child {
        position: absolute;
        font-size: 12px;
        left: 13px;
      }
    }
  }

  &__infos {
    padding-left: 10px;
  }

  &__headline {
    margin-bottom: 5px;
  }

  &__phone {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-bottom: 6px;
  }

  &__text {
    font-size: 13px;
    line-height: 1.3;
  }
}

.customer-display__value {
  margin-bottom: 8px;
}

.popover .arrow::after {
  border-bottom-color: $white !important;
}

.modal-content {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border: 0;

  .modal-body .float-right .fa-times {
    color: $primary;
  }
}

.iso__overlay__backdrop {
  z-index: 99999999 !important;
  opacity: 0.83 !important;
}

.iso__overlay__spinner__container {
  z-index: 99999999 !important;
}

.uc-embedding-wrapper {
  top: 0 !important;
  transform: translate(-50%, 0) !important;
  position: relative !important;
  white-space: initial;

  &--custom {
    transform: none !important;
    max-width: 450px;
    padding: 1rem;
    box-shadow: 0 0 8px $gray-400;
    border-radius: 4px;
    margin: 0 auto;
    line-height: 1.2;

    button {
      @extend .btn;
      @extend .btn-primary;
      width: 100%;
      height: 40px;
    }
  }
}

.video-wrapper > .uc-embedding-wrapper {
  position: absolute;
  top: 50% !important;
  margin: 0;
  transform: translate(-50%, -50%) !important;
}

// prevent the IBE text color from being overridden by CMS only styles
.ibe,
.search-collapsible__outer,
.modal {
  color: $base-1;
}

.camper-extra-info {
  display: inline-block;
}

.camper__headline {
  font-size: 18px;
  font-weight: $font-weight-bold;
  margin-bottom: 20px;
}

.information-layer__modal .modal-content,
.vehicle-comparison__inner,
.fixed-scroll-element-container,
.camper-extras__item__popover,
.camper-item__popover,
.camper-item__rates__modal__inner {
  scrollbar-color: $primary $base-3;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    border-radius: 4px;
    background-color: $base-3;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: $base-3;
  }
}

.camper-extras__item__total--price {
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
}

.terms-and-conditions__checkbox {
  &__collapse {
    &-trigger {
      margin-top: 10px;
      color: $link-color;
      text-decoration: underline;

      &:hover {
        color: $link-color-hover;
      }

      .terms-and-conditions--error & {
        color: $red;

        &:hover {
          color: $red;
        }
      }
    }

    &-content > div {
      padding-top: 20px;
      line-height: 1.5;

      > div:first-child,
      > div:nth-child(8) {
        font-weight: 700;
        margin-bottom: 5px;
      }

      > div:nth-child(6) {
        margin: 12px 0 6px;
      }
    }
  }
}

.font-weight-boldest {
  font-weight: 700;
}

.transparent-overlay .iso__overlay__backdrop {
  background-color: transparent !important;
}

.awssld__controls button {
  background-color: white;
  height: 14px;
  width: 14px;
  margin: 10px 9px 0 6px;
  border-radius: 3px;
  visibility: initial;
}

.nav-container {
  min-width: map-get($grid-breakpoints, 'sm');
  @include medium {
    min-width: map-get($grid-breakpoints, 'md');
  }
  @include large {
    min-width: map-get($grid-breakpoints, 'lg');
  }
  @include xlarge {
    min-width: map-get($grid-breakpoints, 'xl');
  }
}

.ibe {
  .footer {
    &.padding-bottom {
      padding-bottom: 80px;
      @include large {
        padding-bottom: initial;
      }
    }
  }
}

.modal .modal-dialog {
  margin: 0.5rem;

  @include small {
    margin: 0.5rem;
  }
}

.modal.fade .modal-dialog {
  transform: translate(0, 0);

  &.traveller-builder__remove-modal {
    transform: translate(0, -50%);
    top: 50%;
  }
}

.modal.fade
  .modal-dialog:not(.information-layer__modal):not(.leave-modal):not(.vehicle-comparison):not(.camper-item__rates__modal):not(.camper-item__popover__modal) {
  transform: translate(-50%, -100%) !important;
}

.modal.show
  .modal-dialog:not(.traveller__go-back):not(.information-layer__modal):not(.vehicle-comparison):not(.camper-item__rates__modal):not(.camper-item__popover__modal) {
  left: 50%;
  transform: translate(-50%, -100%);

  &.leave-modal {
    transform: translate(-50%, 100%);
  }
}

@charset "utf-8";

// All of bootstrap, but our own vars file

// only bootstrap overrides/extends here
// put custom or non-bootstrap styles into Base.scss

* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
}

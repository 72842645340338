.nested-geounits-select {
  &__option {
    font-weight: 400;
    padding-left: 25px;

    &:hover {
      background-color: $secondary-stroked-hover;
    }

    &--parent {
      font-weight: $font-weight-bold;
      padding-left: 12px;
    }

    &--selected {
      background-color: $secondary;
      color: $white;

      &:hover {
        background-color: $secondary;
        color: $white;
      }
    }
  }

  &__caret {
    background-color: transparent;
  }
}

@import 'Vars';
@import './utils/mediaQueries';

.cmb {
  font-family: var(--font-family-base);
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $base-1;
}

.affiliate-cmp-link {
  display: inline-block;
  font-size: 0.875rem;
  position: relative;
  margin-bottom: 12px;
  top: 34px;

  @include small {
    position: absolute;
    right: 0;
    top: 8px;
    margin-bottom: 0;
    z-index: 1;
  }
}

.module-container {
  position: relative;
  padding-top: 4px;
}

.camper-loading {
  width: 207px;
  position: relative;
  overflow: hidden;

  @keyframes camperAnimation {
    0% {
      transform: translateX(-30%);
    }
    100% {
      transform: translateX(90%);
    }
  }

  &__camper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    animation: camperAnimation 2.8s linear 0s infinite forwards;
  }
}

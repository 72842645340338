.select {
  &__control {
    height: 60px;
    border-radius: 4px !important;
    border: 1px solid $base-2 !important;
    cursor: pointer !important;

    &:hover {
      background-color: $base-4;
    }

    &--menu-is-open {
      border-color: $primary !important;

      .select__indicator svg {
        transform: rotate(180deg);
      }
    }

    .input--error & {
      border-color: $red !important;
      color: $red !important;

      &--menu-is-open {
        border-color: $red !important;
      }

      .select__placeholder {
        color: $red;
      }
    }
  }

  &__indicator {
    svg {
      color: $base-1;
      transition: transform 0.25s ease-in-out;
    }

    &-separator {
      display: none;
    }
  }
}

input.form-control {
  border-radius: 4px !important;
  border: 1px solid $base-2 !important;
  height: 60px;

  &:hover {
    background-color: $base-4;
  }

  &:active,
  &:focus {
    border-color: $primary !important;
    box-shadow: none;
    outline: 0;
  }

  .input--error & {
    border-color: $red !important;
    color: $red !important;

    &:active,
    &:focus {
      border-color: $red !important;
    }
  }
}

.react-time-picker {
  @extend input, .form-control;

  &__wrapper {
    border: 0;
    height: 100%;
  }
}

.form__form-builder__container .input--error {
  .form__error {
    top: 52px !important;
    line-height: 1.1;
    margin: 10px 0;
  }

  svg {
    top: 50%;
    transform: translateY(-50%);
  }

  .form-control::placeholder {
    color: $red;
  }
}

.iso__date-range {
  &-input {
    height: 60px;
    border-radius: 4px !important;
    border: 1px solid $base-2 !important;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: $base-4;
    }

    &:active,
    &:focus {
      box-shadow: none;
      outline: 0;
    }

    > div > div {
      color: $base-1 !important;
      font-weight: 400;
    }
  }

  .react-datepicker__input-container .btn {
    &:active,
    &:focus {
      box-shadow: none;
      outline: 0;
    }

    &:hover {
      background-color: $base-4;
    }
  }
}

.iso__service-search {
  button.btn-secondary {
    height: 60px;
  }

  .iso__date-range {
    &-input {
      border-color: $base-3 !important;
      box-shadow: 0 3px 6px $gray-400;
      border-radius: 0 4px 4px 0 !important;
    }
  }

  .select {
    &__control {
      border-color: $base-3 !important;
      box-shadow: 0 3px 6px $gray-400;
      border-radius: 0 4px 4px 0 !important;
    }
  }
}

.custom-checkbox.custom-control {
  margin-right: 6px;
}

.custom-control-input[type='radio'],
.custom-control-input[type='checkbox'] {
  + .custom-control-label {
    top: -5px;
    position: relative;
    cursor: pointer;

    &::before {
      height: 24px;
      width: 24px;
      border-color: $base-2;
      border-radius: 4px;
      border-width: 2px;
    }

    &::after {
      height: 24px;
      width: 24px;
      border-radius: 4px;
    }

    &:hover {
      &::before {
        background-color: $primary-hover;
      }
    }
  }

  &[disabled],
  &:disabled {
    + .custom-control-label {
      cursor: default;

      &::before {
        background-color: $gray-200;
      }
    }
  }

  &:checked + .custom-control-label {
    &::before {
      border-color: $primary;
    }

    &::after {
      background-color: $primary;
    }

    &:hover {
      &::after {
        background-color: $primary-hover;
      }
    }
  }

  &:focus,
  &:active {
    + .custom-control-label {
      &::after,
      &::before {
        box-shadow: none;
        outline: 0;
      }
    }
  }
}

.custom-control-input[type='radio'] + .custom-control-label {
  &::before,
  &::after {
    border-radius: 50%;
  }
}

.iso__baseLayoutFilter {
  .form-check {
    margin-bottom: 6px;
  }
}

.camper__search {
  &__checkbox {
    height: 60px;
    display: flex;
  }

  &__icon {
    background-color: $base-3;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 3px 6px $gray-400;

    svg {
      color: $secondary;
    }

    path {
      color: $secondary;
    }
  }
}

.iso__date-range-input_divider {
  path {
    color: $secondary;
  }
}

.search-collapsible {
  .link-button {
    @extend .btn;
    @extend .btn-secondary;
    @extend .btn-secondary--stroked;
  }

  > div {
    color: $primary;
  }
}

.form-control,
.form-control::placeholder {
  color: $base-1;
  font-weight: 400;
}

.form__add {
  margin-top: 15px;
  background-color: $tertiary !important;
  border: 2px solid $tertiary !important;
  &:hover {
    background-color: lighten($tertiary, 10%) !important;
    border: 2px solid lighten($tertiary, 10%) !important;
  }
}

.form__datepicker.input--error .iso__date-range-input.btn {
  border-color: $red !important;

  > div > div {
    color: $red !important;
  }
}

.form__form-builder__general-error {
  left: 1rem;
  top: -1px;
  font-size: 0.85rem;
}

.element-renderer {
  .form__input__placeholder {
    padding-left: 0.75rem;
    width: calc(100% - 2px);
    border-radius: 4px;
    cursor: text;

    &:hover {
      background-color: $base-4;
    }
  }
}

.checkout__customer,
.checkout__traveller {
  .row-creator {
    align-items: flex-start;
  }

  .element-renderer {
    margin-top: 1.5rem;

    &.element-renderer--external {
      display: none;

      @include large {
        display: flex;
      }
    }

    label {
      display: none;
    }
  }
}

.iso__date-range,
.iso__date-range.iso__date-range--disabled,
.react-datepicker__input-container,
.iso__date-range-input {
  width: 100%;
  background-color: $base-4;
  cursor: default;
  box-shadow: 0 3px 6px $gray-400;

  > div > div {
    color: $gray-400 !important;

    path {
      color: lighten($secondary, 25%);
    }
  }
}

.iso__date-range-input {
  &_text-container {
    > span {
      justify-content: center;
      flex-wrap: wrap;

      > span:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.select--is-disabled .select__control *,
.select--is-disabled .select__control {
  color: $gray-400 !important;
}

.react-datepicker__day--disabled,
.react-datepicker__day--excluded {
  &:hover {
    background-color: $orangered;
    color: $white;
    border-radius: 0.3rem;
    cursor: default;
  }
}

.react-datepicker-popper .react-datepicker .react-datepicker__triangle {
  border-bottom-color: $primary;

  &::after,
  &::before {
    border-bottom-color: $primary;
  }
}

.react-datepicker {
  border: 0;
  box-shadow: 0 0 6px #00000099;

  &__aria-live {
    display: none;
  }

  .react-datepicker__header {
    background-color: $primary;

    * {
      color: $white;
    }

    select,
    option {
      color: $base-1;
    }
  }

  .react-datepicker__navigation {
    &--previous {
      border-right-color: $white;
    }

    &--next {
      border-left-color: $white;
    }
  }

  .react-datepicker__day--in-range {
    background-color: $primary;
  }

  .react-datepicker__month-container {
    &:nth-last-child(2) .react-datepicker__header {
      border-top-right-radius: 0;
    }

    &:last-child .react-datepicker__header {
      border-top-left-radius: 0;
    }
  }
}

.iso__service-search {
  .nested-geounits-select__menu,
  input.form-control {
    border-color: $base-3 !important;
    box-shadow: 0 3px 6px $gray-400;
    border-radius: 0 4px 4px 0 !important;
    cursor: pointer;
  }
}

.camper__search {
  &__pickup,
  &__dropoff,
  &__checkbox {
    @media (max-width: 1280px) {
      padding-top: 0 !important;
    }
  }
  &__dropoff {
    @media (max-width: 767px) {
      padding-top: 1rem;
    }
  }
  &__date-range {
    padding-top: 1rem;
    @media (max-width: 767px) {
      padding-top: 0;
    }
    @media (max-width: 575px) {
      display: block;
    }
  }
  &__icon--dropoff {
    background-color: $base-3;
  }
  &__checkbox {
    justify-content: center;
  }
}

.traveler-selection {
  width: 100%;
  background-color: $base-4;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 3px 6px $gray-400;

  &:hover {
    background-color: $orangered;
    color: $white;
    border-radius: 0.3rem;
  }

  &-wrapper {
    height: 100%;
    color: $base-3;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 3px 6px $gray-400;
  }

  &-container {
    display: flex;
    width: 100%;
    padding: 0 0 0 16px;

    @media (max-width: 575px) {
      padding: 0;
      margin-top: 16px;
      height: 60px;
    }
  }

  button {
    background-color: #fff;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: $base-4;
    }
  }

  &-input {
    &_text-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &-icon {
    flex: 0 1 60px;
    display: flex;
    min-width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0 0 4px;
    background-color: $base-3;
    box-shadow: 0 3px 6px $gray-400;
  }

  &__dropdown {
    position: absolute;
    width: calc(100% - 14px);
    top: 110%;
    background-color: #fff;
    border: 1px solid $base-3;
    box-shadow: 0 3px 6px $gray-400;
    border-radius: 6px;
    padding: 16px 12px 6px 12px;
    z-index: 1;
    left: 0.5rem;

    @include medium {
      width: calc(50% - 1.3rem);
      left: initial;
      right: 0.5rem;
    }

    &__traveler-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-direction: column;

      @include small {
        flex-direction: row;
      }

      &__wrapper {
        border-top: 1px solid $base-3;
        border-bottom: 1px solid $base-3;
        flex: 0 1 auto;
        display: flex;
        align-items: center;

        span {
          display: inline-flex;
          width: 10px;
        }
      }

      button {
        width: 60px;
        height: 60px;
        background-color: $base-3;
        border: 1px solid $base-3;
        border-radius: 4px;
        cursor: default;
      }

      span {
        color: black;
        margin: 0 10px;
      }
    }
  }
}

.module-container {
  .traveler-selection__dropdown__traveler-count {
    @media (min-width: 1200px) and (max-width: 1279px) {
      flex-direction: column;
    }

    @media (min-width: 1920px) {
      flex-direction: column;
    }
  }
}

.cmb {
  /* Provide your colors in hsl format! */
  --white: hsl(0, 0%, 100%);
  --white-h: 0;
  --white-s: 0%;
  --white-l: 100%;

  --primary: hsl(219, 53%, 49%);
  --primary-h: 219;
  --primary-s: 53%;
  --primary-l: 49%;
  --primary-a: 1;

  --primary-disabled: hsl(218, 100%, 92%);
  --primary-disabled-h: 218;
  --primary-disabled-s: 100%;
  --primary-disabled-l: 92%;
  --primary-disabled-a: 1;

  --primary-active: hsl(219, 76%, 35%);
  --primary-active-h: 219;
  --primary-active-s: 76%;
  --primary-active-l: 35%;
  --primary-active-a: 1;

  --primary-hover: hsl(218, 51%, 67%);
  --primary-hover-h: 218;
  --primary-hover-s: 51%;
  --primary-hover-l: 67%;
  --primary-hover-a: 1;

  --primary-stroked-hover: hsl(218, 75%, 84%);
  --primary-stroked-hover-h: 218;
  --primary-stroked-hover-s: 75%;
  --primary-stroked-hover-l: 84%;
  --primary-stroked-hover-a: 1;

  --primary-stroked-active: hsl(218, 35%, 54%);
  --primary-stroked-active-h: 218;
  --primary-stroked-active-s: 35%;
  --primary-stroked-active-l: 54%;
  --primary-stroked-active-a: 1;

  --secondary: hsl(220, 42%, 32%);
  --secondary-h: 220;
  --secondary-s: 42%;
  --secondary-l: 32%;
  --secondary-a: 1;

  --secondary-active: hsl(220, 81%, 21%);
  --secondary-active-h: 220;
  --secondary-active-s: 81%;
  --secondary-active-l: 21%;
  --secondary-active-a: 1;

  --secondary-disabled: hsl(220, 18%, 46%);
  --secondary-disabled-h: 220;
  --secondary-disabled-s: 18%;
  --secondary-disabled-l: 46%;
  --secondary-disabled-a: 1;

  --secondary-hover: hsl(218, 51%, 67%);
  --secondary-hover-h: 218;
  --secondary-hover-s: 51%;
  --secondary-hover-l: 67%;
  --secondary-hover-a: 1;

  --secondary-stroked-hover: hsl(218, 75%, 84%);
  --secondary-stroked-hover-h: 218;
  --secondary-stroked-hover-s: 75%;
  --secondary-stroked-hover-l: 84%;
  --secondary-stroked-hover-a: 1;

  --secondary-stroked-active: hsl(218, 35%, 54%);
  --secondary-stroked-active-h: 218;
  --secondary-stroked-active-s: 35%;
  --secondary-stroked-active-l: 54%;
  --secondary-stroked-active-a: 1;

  --tertiary: hsl(41, 80%, 55%);
  --tertiary-h: 41;
  --tertiary-s: 80%;
  --tertiary-l: 55%;
  --tertiary-a: 1;

  --base-1: hsl(219, 18%, 19%);
  --base-1-h: 219;
  --base-1-s: 18%;
  --base-1-l: 19%;
  --base-1-a: 1;

  --base-2: hsl(0, 0%, 65%);
  --base-2-h: 0;
  --base-2-s: 0%;
  --base-2-l: 0%;
  --base-2-a: 1;

  --base-3: hsl(218, 17%, 91%);
  --base-3-h: 218;
  --base-3-s: 17%;
  --base-3-l: 91%;
  --base-3-a: 1;

  --base-4: hsl(0, 0%, 96%);
  --base-4-h: 0;
  --base-4-s: 0%;
  --base-4-l: 96%;
  --base-4-a: 1;

  --green: hsl(156, 72%, 41%);
  --green-h: 156;
  --green-s: 72%;
  --green-l: 41%;
  --green-a: 1;

  --green-light: hsl(157, 50%, 83%);
  --green-light-h: 157;
  --green-light-s: 50%;
  --green-light-l: 83%;
  --green-light-a: 1;

  --red: hsl(0, 100%, 50%);
  --red-h: 0;
  --red-s: 100%;
  --red-l: 50%;
  --red-a: 1;

  --orange: hsl(16, 100%, 50%);
  --orange-h: 16;
  --orange-s: 100%;
  --orange-l: 50%;
  --orange-a: 1;

  --link: hsl(213, 50%, 50%);
  --link-h: 213;
  --link-s: 50%;
  --link-l: 50%;
  --link-a: 1;

  --link-hover: hsl(212, 50%, 35%);
  --link-hover-h: 212;
  --link-hover-s: 50%;
  --link-hover-l: 35%;
  --link-hover-a: 1;

  --font-family-base: 'Poppins', sans-serif;
}

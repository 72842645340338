// Override SCSS vars (aka global theme)
// --------------------------------------------
$headings-font-family: var(--font-family-base);
$font-family-sans-serif: var(--font-family-base);
$font-family-base: var(--font-family-base);
$font-family-monospace: 'Roboto', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1360px
) !default;

$primary: var(--primary);
$primary-disabled: var(--primary-disabled);
$primary-active: var(--primary-active);
$primary-hover: var(--primary-hover);
$primary-stroked-active: var(--primary-stroked-active);
$primary-stroked-hover: var(--primary-stroked-hover);

$secondary: var(--secondary);
$secondary-active: var(--secondary-active);
$secondary-disabled: var(--secondary-disabled);
$secondary-hover: var(--secondary-hover);
$secondary-stroked-active: var(--secondary-stroked-active);
$secondary-stroked-hover: var(--secondary-stroked-hover);

$primary-secondary-stroked-disabled: var(--white);

$primary-secondary-stroked: var(--white);

$tertiary: var(--tertiary);
$base-1: var(--base-1);
$base-2: var(--base-2);
$base-3: var(--base-3);
$base-4: var(--base-4);
$green: var(--green);
$green-light: var(--green-light);
$red: var(--red);
$orangered: var(--orange);
$sand: #faefd6;
$beige: #f8e7c3;
$lightgray: #f0f0f0;

$gray-100: #f2f2f2;
$gray-200: #ededed;
$gray-300: #fbf9f5;
$gray-400: #d7d7d7;
$gray-500: #9d9d9c;
$gray-600: #898989;
$gray-700: #666;
$gray-800: #333f;
$gray-900: #28282f;
$black: $gray-800;
$white: #fff;
$background: #f9fbfe;
$gray-base: #333;

$font-weight-normal: 300;
$font-weight-bold: 500 !default;
$font-weight-bolder: 500 !default;
$headings-font-weight: 300;
$body-color: $base-1;
$input-color: $gray-900;
$input-placeholder-color: $gray-500;
$input-focus-border-color: $gray-400;
$border-radius: 0.1rem;
$input-border-radius: 0;
$font-size-base: 1rem !default;
$headings-color: $primary;
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base !default;
$link-color: #417abf;
$link-color-hover: #2d5686;
$alert-warning-color: #9d7d1c;
$alert-warning-background-color: #fff3cd;
$alert-warning-border-color: #ffeeba;
$alert-danger-color: #9d1818;
$alert-danger-background-color: #ffcccc;
$alert-danger-border-color: #ffb8b8;
$alert-success-color: #287656;
$alert-success-background-color: #d2f0e4;
$alert-success-border-color: #c0ead9;

$grid-gutter-width: 1rem;

$spacers: () !default;
$spacer: 1rem;

$spacers: map-merge(
  (
    6: (
      $spacer * 3
    ),
    7: (
      $spacer * 5
    ),
    8: (
      $spacer * 10
    )
  ),
  $spacers
);

.btn.btn-primary {
  color: $white;
  background-color: $primary;
  border: 2px solid $primary;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;
  font-size: 18px;
  line-height: 20px;
  height: 60px;
  font-weight: $font-weight-bold;

  &:hover {
    background-color: $primary-hover;
    border: 1px solid $primary-hover;
  }

  &:active,
  &:focus {
    background-color: $primary-active;
    border: 1px solid $primary-active;
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    background-color: $primary-disabled;
    border: 1px solid $primary-disabled;
    color: $primary;
  }

  &.btn-primary--stroked {
    background-color: $primary-secondary-stroked;
    border-color: $primary;
    color: $primary;

    &:hover {
      background-color: $primary-stroked-hover;
      border: 2px solid $primary;
    }

    &:active,
    &:focus {
      background-color: $primary-stroked-active;
      border: 2px solid $primary;
      outline: 0;
      box-shadow: none;
    }

    &:disabled {
      background-color: $primary-secondary-stroked-disabled;
      border: 2px solid $primary-hover;
      color: $primary-hover;
    }
  }
}

.btn.btn-secondary {
  color: $white;
  background-color: $secondary;
  border: 2px solid $secondary;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 18px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $secondary-hover;
    border: 1px solid $secondary-hover;
  }

  &:active,
  &:focus {
    background-color: $secondary-active;
    border: 1px solid $secondary-active;
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    background-color: $secondary-disabled;
    border: 1px solid $secondary-disabled;
    color: $secondary;
  }

  &.btn-secondary--stroked {
    background-color: $primary-secondary-stroked;
    border-color: $secondary;
    color: $secondary;

    &:hover {
      background-color: $secondary-stroked-hover;
      border: 2px solid $secondary;
    }

    &:active,
    &:focus {
      background-color: $secondary-stroked-active;
      border: 2px solid $secondary;
      outline: 0;
      box-shadow: none;
    }

    &:disabled {
      background-color: $primary-secondary-stroked-disabled;
      border: 2px solid $secondary-disabled;
      color: $secondary-disabled;
    }
  }
}

.btn {
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
}
